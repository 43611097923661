import React from "react"
import Layout from "@components/layout"
import { Link, graphql } from "gatsby"
import Seo from "@components/seo"

import useWindowSize from "../hooks/useWindowSize"

// import WebriQForm from "@webriq/gatsby-webriq-form"

//lazyloading
import { LazyLoadComponent } from "react-lazy-load-image-component"

//images
import { StaticImage, GatsbyImage } from "gatsby-plugin-image"
// import { BgImage } from "gbimage-bridge"
// import HeroImg from "@images/ff-mark-v2.5-website.png"
// import VideoIcon from "@images/video-icon-sm.svg"
// import ImagesIcon from "@images/images-icon.svg"

//icons
import HeartIcon from "@images/heart-icon.svg"
import { node } from "prop-types"
const _ = require("lodash")
// import rightArrowIcon from "@images/right-angle.svg"

//swiper
// import BlogSwiperTrending from "@components/swiper/blog-swiper-trending"

const slugify = require("slugify")

const Blog = ({ location, data }) => {
  const posts = data.allSanityPost.edges
  const featuredPosts = data.allSanityFeaturedpost.edges
  const groupByCategory = data.allSanityPost.group

  const sortByCreated = groupByCategory.map(cat => {
    return _.orderBy(cat.nodes, ['createdAt', 'title'], ['asc', 'asc']).map(item => {
      return {
          defaultCategory: cat.fieldValue,
          ...item
        }
      })
  }).flat().map(posts => {
    return {
        ...posts,
        // featured: _.filter(posts.categories, {title: posts.defaultCategory, featuredCategories: false})
    }
  })
  // .filter(post => post.featured.length !== 0)


  // const postByCategory = _.orderBy(Object.entries(_.groupBy(_.uniqBy(sortByCreated, "title"), "defaultCategory")).map(key => {
  //   return key[1][0]
  // }).flat(), ["_createdAt"], ["desc"])

  // const postByCategory = _.orderBy(Object.entries(_.groupBy(_.uniqBy(sortByCreated, "title"), "defaultCategory")).map(key => {
  //   return key[1][0]
  // }).flat(), ["_createdAt"], ["desc"])
  const postByCategory = _.orderBy(Object.entries(_.groupBy(sortByCreated, "defaultCategory")).map(key => {
    return _.orderBy(key[1], ["_createdAt"], ["desc"])[0]
  }), ["_createdAt"], ["desc"])


  const size = useWindowSize()

  // const filterTrending = data.allSanityPost.group.filter(
  //   fltr => fltr.fieldValue === "Trending"
  // )

  // const filterBusiness = data.allSanityPost.group.filter(
  //   fltr => fltr.fieldValue === "Business"
  // )

  // console.log("filterBusiness", filterBusiness)

  // const filterCreative = data.allSanityPost.group.filter(
  //   fltr => fltr.fieldValue === "Creative"
  // )

  // const filterNews = data.allSanityPost.group.filter(
  //   fltr => fltr.fieldValue === "News"
  // )

  // const filterStategy = data.allSanityPost.group.filter(
  //   fltr => fltr.fieldValue === "Strategy"
  // )

  const filterResearch = data.allSanityPost.group.filter(
    fltr => fltr.fieldValue === "Thought Provoking"
  )

  // const filterExclusive = data.allSanityPost.group.filter(
  //   fltr => fltr.fieldValue === "Exclusive"
  // )

  const featuredCat = data.allSanityCategory.nodes

  // console.log("cat", featuredCat)

  return (
    <Layout location={location}>
      <Seo title="Blog" />
      <div className="blog-items" style={{ marginTop: "4.2rem" }}>
        <div className="container-fluid px-md-0">
          <div className="row g-0">
            <div className="col-md-12 col-lg-4">
              <div className="row g-0">
                <div className="col-md-12 col-lg-12 mt-lg-0 mt-md-0 mt-5">
                  {featuredPosts?.map(featuredBlog => (
                    <>
                      {featuredBlog?.node?.post
                        ?.slice(0, 2)
                        .map(featuredLeftPos => (
                          <>
                            {/* {console.log("featuredLeftPos ni", featuredLeftPos)} */}
                            <div className="blog-item blog-item-overlay-text d-grid mb-lg-0 mb-md-0 mb-5">
                              <Link
                                to={`/${featuredLeftPos?.slug?.current}`}
                                className="link-unstyled d-grid"
                              >
                                <div className="blog-image-wrapper image-wrapper-rectangle">
                                  <GatsbyImage
                                    image={
                                      featuredLeftPos?.mainImage
                                        ? featuredLeftPos.mainImage.asset
                                            .gatsbyImageData
                                        : ""
                                    }
                                    style={{
                                      gridArea: "1/1",
                                      zIndex: 1,
                                      position: "absolute",
                                      height: "100%",
                                      width: "100%",
                                    }}
                                  />
                                  <div
                                    style={{
                                      gridArea: "1/1",
                                      placeItems: "center",
                                    }}
                                  />
                                  {/* <div
                            className="blog-item-type"
                            title="video"
                          >
                            <img src={VideoIcon} alt="Video Icon" />
                          </div> */}
                                </div>
                              </Link>
                              <div className="blog-text">
                                <div className="blog-item-categories">
                                  <div className="blog-item-categories">
                                    {featuredLeftPos?.categories?.map(cat => (
                                      <Link
                                        to={`/category/${slugify(
                                          cat.title.toLowerCase()
                                        )}`}
                                        className="blog-category-tag"
                                      >
                                        <span
                                          className={`category-color ${(cat.title ===
                                            "Business" &&
                                            "business-color") ||
                                            (cat.title ===
                                              "Thought Provoking" &&
                                              "thought-color") ||
                                            (cat.title === "Newsworthy" &&
                                              "news-color") ||
                                            (cat.title === "Education" &&
                                              "edu-color") ||
                                            (cat.title === "Featured" &&
                                              "feat-color") ||
                                            (cat.title ===
                                              "Founder Perspective" &&
                                              "founder-color") ||
                                            (cat.title ===
                                              "Health & Wellness" &&
                                              "health-color") ||
                                            (cat.title === "Inspiration" &&
                                              "inspi-color") ||
                                            "default-color"}`}
                                        />
                                        {cat.title}
                                      </Link>
                                    ))}
                                  </div>
                                </div>
                                <Link
                                  to={`/${featuredLeftPos?.slug?.current}`}
                                  className="link-unstyled"
                                >
                                  <h3
                                    className="h4 mt-2 mb-3 blog-item-title"
                                    style={{ fontWeight: 600 }}
                                  >
                                    {featuredLeftPos?.title}
                                  </h3>
                                </Link>
                                <span className="d-block small blog-item-details">
                                  {featuredLeftPos?.authors.length !== 0
                                    ? "Posted by"
                                    : ""}
                                  {featuredLeftPos?.authors.map(author => (
                                    <div className="d-inline-block">
                                      <Link
                                        to={`/author/${author?.person?.slug.current}`}
                                        className="link-unstyled me-2"
                                        style={{ fontWeight: 700 }}
                                      >
                                        <span className="ps-2 pe-2">
                                          {author?.person?.name}
                                        </span>
                                        <span>•</span>
                                      </Link>
                                    </div>
                                  ))}
                                  {featuredLeftPos?.publishedAt ? (
                                    <div className="d-inline-block">
                                      <span>
                                        {featuredLeftPos?.publishedAt}
                                      </span>
                                    </div>
                                  ) : null}
                                  {featuredLeftPos?.minutes ? (
                                    <span className="ms-2">
                                      <span className="me-2">•</span>
                                      {featuredLeftPos?.minutes} min read
                                    </span>
                                  ) : null}
                                </span>
                              </div>
                            </div>
                          </>
                        ))}
                    </>
                  ))}
                </div>
              </div>
            </div>
            {featuredPosts?.map(postfullHeight => (
              <>
                {postfullHeight?.node?.post
                  .slice(2, 3)
                  .map(featureCenterPos => (
                    <div className="col-lg-4 mb-5 mb-md-0 blog-item-full-height testing">
                      <div className="blog-item blog-item-overlay-text">
                        <Link
                          to={`/${featureCenterPos?.slug.current}`}
                          className="link-unstyled"
                        >
                          <div className="blog-image-wrapper image-wrapper-rectangle">
                            <GatsbyImage
                              image={
                                featureCenterPos?.mainImage
                                  ? featureCenterPos?.mainImage.asset
                                      .gatsbyImageData
                                  : ""
                              }
                              style={{
                                gridArea: "1/1",
                                zIndex: 1,
                                position: "absolute",
                                height: "100%",
                                width: "100%",
                              }}
                            />
                            <div
                              style={{
                                gridArea: "1/1",
                                placeItems: "center",
                              }}
                            />
                            {/* <div className="blog-item-type" title="video">
                        <img src={VideoIcon} alt="Video Icon" />
                      </div> */}
                          </div>
                        </Link>
                        <div className="blog-text">
                          <div className="blog-item-categories">
                            {featureCenterPos?.categories?.map(cat => (
                              <Link
                                to={`/category/${slugify(
                                  cat.title.toLowerCase()
                                )}`}
                                className="blog-category-tag"
                              >
                                <span
                                  className={`category-color ${(cat.title ===
                                    "Business" &&
                                    "business-color") ||
                                    (cat.title === "Thought Provoking" &&
                                      "thought-color") ||
                                    (cat.title === "Newsworthy" &&
                                      "news-color") ||
                                    (cat.title === "Education" &&
                                      "edu-color") ||
                                    (cat.title === "Featured" &&
                                      "feat-color") ||
                                    (cat.title === "Founder Perspective" &&
                                      "founder-color") ||
                                    (cat.title === "Health & Wellness" &&
                                      "health-color") ||
                                    (cat.title === "Inspiration" &&
                                      "inspi-color") ||
                                    "default-color"}`}
                                />
                                {cat.title}
                              </Link>
                            ))}
                          </div>
                          <Link
                            to={`/${featureCenterPos?.slug.current}`}
                            className="link-unstyled"
                          >
                            <h3
                              className="h4 mt-2 mb-3 blog-item-title"
                              style={{ fontWeight: 600 }}
                            >
                              {featureCenterPos?.title}
                            </h3>
                          </Link>
                          <span className="d-block small blog-item-details">
                            {featureCenterPos?.authors.length !== 0
                              ? "Posted by"
                              : ""}
                            {featureCenterPos?.authors.map(author => (
                              <div className="d-inline-block">
                                <Link
                                  to={`/author/${author?.person?.slug.current}`}
                                  className="link-unstyled me-2"
                                  style={{ fontWeight: 700 }}
                                >
                                  <span className="ps-2 pe-2">
                                    {author?.person?.name}
                                  </span>
                                  <span>•</span>
                                </Link>
                              </div>
                            ))}
                            {featureCenterPos?.publishedAt ? (
                              <div className="d-inline-block">
                                <span>{featureCenterPos?.publishedAt}</span>
                              </div>
                            ) : null}
                            {featureCenterPos?.minutes ? (
                              <span className="ms-2">
                                <span className="me-2">•</span>
                                {featureCenterPos?.minutes} min read
                              </span>
                            ) : null}
                          </span>
                        </div>
                      </div>
                    </div>
                  ))}
              </>
            ))}
            <div className="col-md-12 col-lg-4">
              <div className="row g-0">
                <div className="col-md-12 col-lg-12">
                  {featuredPosts?.map(featuredBlog => (
                    <>
                      {featuredBlog?.node?.post
                        ?.slice(3, 5)
                        .map(featuredLeftPos => (
                          <>
                            {/* {console.log("featuredLeftPos ni", featuredLeftPos)} */}
                            <div className="blog-item blog-item-overlay-text d-grid mb-lg-0 mb-md-0 mb-5">
                              <Link
                                to={`/${featuredLeftPos?.slug?.current}`}
                                className="link-unstyled d-grid"
                              >
                                <div className="blog-image-wrapper image-wrapper-rectangle">
                                  <GatsbyImage
                                    image={
                                      featuredLeftPos?.mainImage
                                        ? featuredLeftPos.mainImage.asset
                                            .gatsbyImageData
                                        : ""
                                    }
                                    style={{
                                      gridArea: "1/1",
                                      zIndex: 1,
                                      position: "absolute",
                                      height: "100%",
                                      width: "100%",
                                    }}
                                  />
                                  <div
                                    style={{
                                      gridArea: "1/1",
                                      placeItems: "center",
                                    }}
                                  />
                                  {/* <div
                            className="blog-item-type"
                            title="video"
                          >
                            <img src={VideoIcon} alt="Video Icon" />
                          </div> */}
                                </div>
                              </Link>
                              <div className="blog-text">
                                <div className="blog-item-categories">
                                  <div className="blog-item-categories">
                                    {featuredLeftPos?.categories?.map(cat => (
                                      <Link
                                        to={`/category/${slugify(
                                          cat.title.toLowerCase()
                                        )}`}
                                        className="blog-category-tag"
                                      >
                                        <span
                                          className={`category-color ${(cat.title ===
                                            "Business" &&
                                            "business-color") ||
                                            (cat.title ===
                                              "Thought Provoking" &&
                                              "thought-color") ||
                                            (cat.title === "Newsworthy" &&
                                              "news-color") ||
                                            (cat.title === "Education" &&
                                              "edu-color") ||
                                            (cat.title === "Featured" &&
                                              "feat-color") ||
                                            (cat.title ===
                                              "Founder Perspective" &&
                                              "founder-color") ||
                                            (cat.title ===
                                              "Health & Wellness" &&
                                              "health-color") ||
                                            (cat.title === "Inspiration" &&
                                              "inspi-color") ||
                                            "default-color"}`}
                                        />
                                        {cat.title}
                                      </Link>
                                    ))}
                                  </div>
                                </div>
                                <Link
                                  to={`/${featuredLeftPos?.slug?.current}`}
                                  className="link-unstyled"
                                >
                                  <h3
                                    className="h4 mt-2 mb-3 blog-item-title"
                                    style={{ fontWeight: 600 }}
                                  >
                                    {featuredLeftPos?.title}
                                  </h3>
                                </Link>
                                <span className="d-block small blog-item-details">
                                  {featuredLeftPos?.authors.length !== 0
                                    ? "Posted by"
                                    : ""}
                                  {featuredLeftPos?.authors.map(author => (
                                    <div className="d-inline-block">
                                      <Link
                                        to={`/author/${author?.person?.slug.current}`}
                                        className="link-unstyled me-2"
                                        style={{ fontWeight: 700 }}
                                      >
                                        <span className="ps-2 pe-2">
                                          {author?.person?.name}
                                        </span>
                                        <span>•</span>
                                      </Link>
                                    </div>
                                  ))}
                                  {featuredLeftPos?.publishedAt ? (
                                    <div className="d-inline-block">
                                      <span>
                                        {featuredLeftPos?.publishedAt}
                                      </span>
                                    </div>
                                  ) : null}
                                  {featuredLeftPos?.minutes ? (
                                    <span className="ms-2">
                                      <span className="me-2">•</span>
                                      {featuredLeftPos?.minutes} min read
                                    </span>
                                  ) : null}
                                </span>
                              </div>
                            </div>
                          </>
                        ))}
                    </>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{ paddingBottom: "10rem" }}>
        <div className="container">
          <LazyLoadComponent>
            <div className="row justify-content-between responsive-gutter mt-5">
              <div className="col-xl-7 col-lg-7 col-md-12">
                {size.width <= "768.99" ? (
                  <div className="mb-5">
                    <div>
                      <div className="px-4 py-5 bg-dark-blue text-white text-center rounded-25">
                        <h2 className="h5" style={{ fontWeight: 700 }}>
                          {/* Subscribe to Our Newsletter */}
                          Stay Connected!
                        </h2>
                        {/* <p className="small">
                      Get our latest updates straight to your inbox!
                    </p> */}
                        <iframe
                          title="Subscribe"
                          scrolling="no"
                          src="https://www.forms.fluofoundation.org/szajtg"
                          style={{
                            border: "0px;",
                            width: "100%",
                            height: "120px",
                          }}
                        ></iframe>
                      </div>
                    </div>
                    <div className="pt-5">
                      <div className="cta-box donation-cta text-center text-white bg-dark rounded-25">
                        <StaticImage
                          style={{
                            gridArea: "1/1",
                            borderRadius: 25,
                            zIndex: 1,
                            content: "",
                            position: "absolute",
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100%",
                          }}
                          layout="fullWidth"
                          placeholder="blurred"
                          // aspectRatio={3 / 1}
                          alt="Category Banner"
                          src={"../images/donation-cta-bg.png"}
                          formats={["auto", "webp", "avif"]}
                        />
                        <div className="content-wrapper">
                          <h2 style={{ fontWeight: 700 }}>Make a Donation</h2>
                          <p className="mt-4 mb-4">
                            Driven by a bold vision for the profound
                            transformation in the mental health of young
                            individuals, the FLUO Foundation relies on the
                            charitable donations of those who are ready to rally
                            behind our youth.
                          </p>
                          <Link
                            className="btn btn-primary donate-btn pulse btn-lg btn-icon d-flex justify-content-center align-items-center mx-auto"
                            role="button"
                            href="/donate.html"
                            style={{ width: 186, height: 54, fontWeight: 600 }}
                          >
                            Donate
                            <img
                              src={HeartIcon}
                              alt="Heart Icon"
                              className="ms-2"
                            />
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
                <div className="d-grid gap-5 mt-3">
                  {/* <h2
                    className="display-4 bordered-heading"
                    style={{ fontWeight: 700 }}
                  >
                    Thought Provoking
                  </h2> */}
                  {postByCategory.map(exclusive => {
                    return (
                      <div className="blog-item blog-item-overlay-text d-grid mb-lg-0 mb-md-0 mb-5">
                        <Link
                          to={`/${exclusive.slug.current}`}
                          className="link-unstyled d-grid"
                        >
                          <div className="blog-image-wrapper image-wrapper-rectangle rounded-25">
                            {exclusive.mainImage ? (
                              <GatsbyImage
                                image={
                                  exclusive.mainImage
                                    ? exclusive.mainImage.asset
                                        .gatsbyImageData
                                    : ""
                                }
                                style={{
                                  gridArea: "1/1",
                                  zIndex: 1,
                                  position: "absolute",
                                  height: "100%",
                                  width: "100%",
                                  borderRadius: 25,
                                }}
                              />
                            ) : (
                              <StaticImage
                                src="https://picsum.photos/seed/picsum/746/410"
                                style={{
                                  gridArea: "1/1",
                                  zIndex: 1,
                                  position: "absolute",
                                  height: "100%",
                                  width: "100%",
                                  borderRadius: 25,
                                }}
                            />
                            )}
                            
                            
                            <div
                              style={{
                                gridArea: "1/1",
                                placeItems: "center",
                              }}
                            />
                            {/* <div
                              className="blog-item-type"
                              title="video"
                              // style={{ zIndex: 3 }}
                            >
                              <img src={VideoIcon} alt="Video Icon" />
                            </div> */}
                          </div>
                        </Link>
                        <div className="blog-text">
                          <div className="blog-item-categories">
                              <Link
                                to={`/category/${slugify(
                                  exclusive.defaultCategory.toLowerCase()
                                )}`}
                                className="blog-category-tag"
                              >
                                <span
                                  className={`category-color ${(exclusive.defaultCategory ===
                                    "Business" &&
                                    "business-color") ||
                                    (exclusive.defaultCategory === "Thought Provoking" &&
                                      "thought-color") ||
                                    (exclusive.defaultCategory === "Newsworthy" &&
                                      "news-color") ||
                                    (exclusive.defaultCategory === "Education" &&
                                      "edu-color") ||
                                    (exclusive.defaultCategory === "Featured" &&
                                      "feat-color") ||
                                    (exclusive.defaultCategory === "Founder Perspective" &&
                                      "founder-color") ||
                                    (exclusive.defaultCategory === "Health & Wellness" &&
                                      "health-color") ||
                                    (exclusive.defaultCategory === "Inspiration" &&
                                      "inspi-color") ||
                                    "default-color"}`}
                                />
                                {exclusive.defaultCategory}
                              </Link>
                          </div>
                          <Link
                            to={`/${exclusive.slug.current}`}
                            className="link-unstyled"
                          >
                            <h3
                              className="h4 mt-2 mb-3 blog-item-title"
                              style={{ fontWeight: 600 }}
                            >
                              {exclusive?.title}
                            </h3>
                          </Link>
                          <span className="d-block small blog-item-details">
                            {exclusive.authors.length !== 0
                              ? "Posted by"
                              : ""}
                            {exclusive.authors.map(author => (
                              <Link
                                to={`/author/${author?.person?.slug.current}`}
                                className="link-unstyled me-2"
                                style={{ fontWeight: 700 }}
                              >
                                <span className="ps-2 pe-2">
                                  {author?.person?.name}
                                </span>
                                <span>•</span>
                              </Link>
                            ))}
                            {/* {exclusive.publishedAt} • 5 min read */}
                            {exclusive?.publishedAt ? (
                              <div className="d-inline-block">
                                <span>{exclusive?.publishedAt}</span>
                              </div>
                            ) : null}
                            {exclusive?.minutes ? (
                              <span className="ms-2">
                                <span className="me-2">•</span>
                                {exclusive?.minutes} min read
                              </span>
                            ) : null}
                          </span>
                        </div>
                      </div>
                      
                    )
                  })}
                </div>
              </div>
              <div className="col-xl-5 col-lg-5 col-md-12 sidebar-column">
                <div className="d-grid gap-5 pt-mobile-spacing">
                  <h2
                    className="display-6 bordered-heading"
                    style={{ fontWeight: "bold" }}
                  >
                    Categories
                  </h2>

                  <div className="d-grid gap-3">
                    {featuredCat.map(featCat => (
                      <Link
                        to={`/category/${slugify(featCat.title.toLowerCase())}`}
                        className="category-banner-link link-unstyled"
                      >
                        {(featCat.title === "Thought Provoking" && (
                          <span className="thought-color">
                            <StaticImage
                              style={{
                                gridArea: "1/1",
                                zIndex: 1,
                                borderRadius: 10,
                                content: "",
                                position: "absolute",
                                top: 0,
                                left: 0,
                                width: "100%",
                                height: "100%",
                              }}
                              layout="fullWidth"
                              placeholder="blurred"
                              // aspectRatio={3 / 1}
                              alt="Category Banner"
                              src={"../images/pexels-burst-374720.jpg"}
                              formats={["auto", "webp", "avif"]}
                            />
                          </span>
                        )) ||
                          (featCat.title === "Founder Perspective" && (
                            <span className="founder-color">
                              <StaticImage
                                style={{
                                  gridArea: "1/1",
                                  zIndex: 1,
                                  borderRadius: 10,
                                  content: "",
                                  position: "absolute",
                                  top: 0,
                                  left: 0,
                                  width: "100%",
                                  height: "100%",
                                }}
                                layout="fullWidth"
                                placeholder="blurred"
                                // aspectRatio={3 / 1}
                                alt="Category Banner"
                                src={"../images/founder.jpg"}
                                formats={["auto", "webp", "avif"]}
                              />
                            </span>
                          )) ||
                          (featCat.title === "Newsworthy" && (
                            <span className="news-cat">
                              <StaticImage
                                style={{
                                  gridArea: "1/1",
                                  zIndex: 1,
                                  borderRadius: 10,
                                  content: "",
                                  position: "absolute",
                                  top: 0,
                                  left: 0,
                                  width: "100%",
                                  height: "100%",
                                }}
                                layout="fullWidth"
                                placeholder="blurred"
                                // aspectRatio={3 / 1}
                                alt="Category Banner"
                                src={
                                  "../images/pexels-daria-shevtsova-1928151.jpeg"
                                }
                                formats={["auto", "webp", "avif"]}
                              />
                            </span>
                          )) ||
                          (featCat.title === "Education" && (
                            <span className="edu-color">
                              <StaticImage
                                style={{
                                  gridArea: "1/1",
                                  zIndex: 1,
                                  borderRadius: 10,
                                  content: "",
                                  position: "absolute",
                                  top: 0,
                                  left: 0,
                                  width: "100%",
                                  height: "100%",
                                }}
                                layout="fullWidth"
                                placeholder="blurred"
                                // aspectRatio={3 / 1}
                                alt="Category Banner"
                                src={"../images/educ.jpg"}
                                formats={["auto", "webp", "avif"]}
                              />
                            </span>
                          )) ||
                          (featCat.title === "Inspiration" && (
                            <span className="inspi-color">
                              <StaticImage
                                style={{
                                  gridArea: "1/1",
                                  zIndex: 1,
                                  borderRadius: 10,
                                  content: "",
                                  position: "absolute",
                                  top: 0,
                                  left: 0,
                                  width: "100%",
                                  height: "100%",
                                }}
                                layout="fullWidth"
                                placeholder="blurred"
                                // aspectRatio={3 / 1}
                                alt="Category Banner"
                                src={"../images/inspiration.jpg"}
                                formats={["auto", "webp", "avif"]}
                              />
                            </span>
                          )) ||
                          (featCat.title === "Health & Wellness" && (
                            <span className="health-color">
                              <StaticImage
                                style={{
                                  gridArea: "1/1",
                                  zIndex: 1,
                                  borderRadius: 10,
                                  content: "",
                                  position: "absolute",
                                  top: 0,
                                  left: 0,
                                  width: "100%",
                                  height: "100%",
                                }}
                                layout="fullWidth"
                                placeholder="blurred"
                                // aspectRatio={3 / 1}
                                alt="Category Banner"
                                src={"../images/health.jpg"}
                                formats={["auto", "webp", "avif"]}
                              />
                            </span>
                          )) ||
                          (featCat.title === "Featured" && (
                            <span className="feat-color">
                              <StaticImage
                                style={{
                                  gridArea: "1/1",
                                  zIndex: 1,
                                  borderRadius: 10,
                                  content: "",
                                  position: "absolute",
                                  top: 0,
                                  left: 0,
                                  width: "100%",
                                  height: "100%",
                                }}
                                layout="fullWidth"
                                placeholder="blurred"
                                // aspectRatio={3 / 1}
                                alt="Category Banner"
                                src={"../images/featured.jpg"}
                                formats={["auto", "webp", "avif"]}
                              />
                            </span>
                          )) ||
                          (featCat.title === "Newsworthy" && (
                            <span className="news-cat">
                              <StaticImage
                                style={{
                                  gridArea: "1/1",
                                  zIndex: 1,
                                  borderRadius: 10,
                                  content: "",
                                  position: "absolute",
                                  top: 0,
                                  left: 0,
                                  width: "100%",
                                  height: "100%",
                                }}
                                layout="fullWidth"
                                placeholder="blurred"
                                // aspectRatio={3 / 1}
                                alt="Category Banner"
                                src={
                                  "../images/pexels-daria-shevtsova-1928151.jpeg"
                                }
                                formats={["auto", "webp", "avif"]}
                              />
                            </span>
                          )) ||
                          (featCat.title && (
                            <span className="business-cat">
                              <StaticImage
                                style={{
                                  gridArea: "1/1",
                                  zIndex: 1,
                                  borderRadius: 10,
                                  content: "",
                                  position: "absolute",
                                  top: 0,
                                  left: 0,
                                  width: "100%",
                                  height: "100%",
                                }}
                                layout="fullWidth"
                                placeholder="blurred"
                                // aspectRatio={3 / 1}
                                alt="Category Banner"
                                src={"../images/pexels-burst-374720.jpg"}
                                formats={["auto", "webp", "avif"]}
                              />
                            </span>
                          ))}
                        <div
                          style={{
                            gridArea: "1/1",
                            placeItems: "center",
                          }}
                        />
                        <div className="blog-category-tag">
                          <Link
                            to={`/category/${slugify(
                              featCat.title.toLowerCase()
                            )}`}
                          >
                            <span
                              className={`category-color ${(featCat.title ===
                                "Business" &&
                                "business-color") ||
                                (featCat.title === "Thought Provoking" &&
                                  "thought-color") ||
                                (featCat.title === "Newsworthy" &&
                                  "news-color") ||
                                (featCat.title === "Education" &&
                                  "edu-color") ||
                                (featCat.title === "Featured" &&
                                  "feat-color") ||
                                (featCat.title === "Founder Perspective" &&
                                  "founder-color") ||
                                (featCat.title === "Health & Wellness" &&
                                  "health-color") ||
                                (featCat.title === "Inspiration" &&
                                  "inspi-color") ||
                                "default-color"}`}
                            />
                            {featCat.title}
                          </Link>
                        </div>
                      </Link>
                    ))}
                  </div>
                </div>
                <div className="d-grid gap-5 pt-spacing">
                  <h2
                    className="display-6 bordered-heading"
                    style={{ fontWeight: 700 }}
                  >
                    Latest posts
                  </h2>
                  {posts.slice(0, 2).map(post => (
                    <div className="blog-item">
                      <div className="blog-text">
                        <div className="blog-item-categories">
                          {post?.node?.categories?.map(cat => (
                            <Link
                              to={`/category/${slugify(
                                cat.title.toLowerCase()
                              )}`}
                              className="blog-category-tag"
                            >
                              <span
                                className={`category-color ${(cat.title ===
                                  "Business" &&
                                  "business-color") ||
                                  (cat.title === "Thought Provoking" &&
                                    "thought-color") ||
                                  (cat.title === "Newsworthy" &&
                                    "news-color") ||
                                  (cat.title === "Education" && "edu-color") ||
                                  (cat.title === "Featured" && "feat-color") ||
                                  (cat.title === "Founder Perspective" &&
                                    "founder-color") ||
                                  (cat.title === "Health & Wellness" &&
                                    "health-color") ||
                                  (cat.title === "Inspiration" &&
                                    "inspi-color") ||
                                  "default-color"}`}
                              />
                              {/* {posts?.node?.categories.title} */}
                              {cat.title}
                            </Link>
                          ))}
                        </div>
                        <Link
                          to={`/${post?.node?.slug?.current}`}
                          className="link-unstyled"
                        >
                          <h3
                            className="h4 mt-2 mb-3 blog-item-title"
                            style={{ fontWeight: 600 }}
                          >
                            {post.node.title}
                          </h3>
                        </Link>
                        <span className="d-block small blog-item-details">
                          {post.node.authors.length !== 0 ? "Posted by" : ""}
                          {post.node.authors.map(author => (
                            <Link
                              to={`/author/${author?.person?.slug.current}`}
                              className="link-unstyled me-2"
                              style={{ fontWeight: 700 }}
                            >
                              <span className="ps-2 pe-2">
                                {author?.person?.name}
                              </span>
                              <span style={{ color: "#000" }}>•</span>
                            </Link>
                          ))}
                          {post?.node?.publishedAt ? (
                            <div className="d-inline-block">
                              <span>{post?.node?.publishedAt}</span>
                            </div>
                          ) : null}
                          {post?.node?.minutes ? (
                            <span className="ms-2">
                              <span className="me-2" styte={{ color: "#000" }}>
                                •
                              </span>
                              {post?.node?.minutes} min read
                            </span>
                          ) : null}
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="d-xl-block d-lg-block d-md-block d-sm-none d-none">
                  <div className="pt-spacing">
                    <div className="px-4 py-5 bg-dark-blue text-white text-center rounded-25">
                      <h2 className="h5" style={{ fontWeight: 700 }}>
                        {/* Subscribe to Our Newsletter */}
                        Stay Connected!
                      </h2>
                      <h5 style={{ fontSize: "0.75rem", fontWeight: 700 }}>
                        Let's Rally Behind our Youth!
                      </h5>                      
                      <iframe
                        title="Subscribe"
                        scrolling="no"
                        src="https://www.forms.fluofoundation.org/szajtg"
                        style={{
                          border: "0px;",
                          width: "100%",
                          height: "120px",
                        }}
                      ></iframe>{" "}
                      {/* <WebriQForm
                      method="POST"
                      name="Subscribe Form"
                      data-form-id="171001ec-e9bd-4079-b701-7143db6156d8"
                      data-thankyou-url="/thank-you"
                    >
                      <div className="subscribe-form d-inline-block mt-4 w-75">
                        <div className="d-flex">
                          <input
                            className="form-control w-100"
                            type="email"
                            placeholder="Email address"
                          />
                          <button
                            className="btn btn-primary btn-white"
                            type="submit"
                          >
                            Subscribe
                          </button>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group">
                          <div className="webriq-recaptcha" />
                        </div>
                      </div>
                    </WebriQForm> */}
                    </div>
                  </div>
                  <div className="pt-spacing">
                    <div className="cta-box donation-cta text-center text-white bg-dark rounded-25">
                      <StaticImage
                        style={{
                          gridArea: "1/1",
                          borderRadius: 25,
                          zIndex: 1,
                          content: "",
                          position: "absolute",
                          top: 0,
                          left: 0,
                          width: "100%",
                          height: "100%",
                        }}
                        layout="fullWidth"
                        placeholder="blurred"
                        // aspectRatio={3 / 1}
                        alt="Category Banner"
                        src={"../images/donation-cta-bg.png"}
                        formats={["auto", "webp", "avif"]}
                      />
                      <div className="content-wrapper">
                        <h2 style={{ fontWeight: 700 }}>Make a Donation</h2>
                        {/* <p className="mt-4 mb-4">
                        While 1 in 5 people will experience a mental illness
                        during their lifetime, everyone faces challenges in life
                        that can impact their mental health. Join us in
                        supporting the effort in promoting mental health for
                        all!
                      </p> */}
                        <p className="mt-4 mb-4">
                          Driven by a bold vision for the profound
                          transformation in the mental health of young
                          individuals, the FLUO Foundation relies on the
                          charitable donations of those who are ready to rally
                          behind our youth.
                        </p>
                        <Link
                          className="btn btn-primary donate-btn pulse btn-lg btn-icon d-flex justify-content-center align-items-center mx-auto"
                          role="button"
                          href="/donate.html"
                          style={{ width: 186, height: 54, fontWeight: 600 }}
                        >
                          Donate
                          <img
                            src={HeartIcon}
                            alt="Heart Icon"
                            className="ms-2"
                          />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </LazyLoadComponent>
        </div>
      </div>
    </Layout>
  )
}

export default Blog

export const blogQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
        author
      }
    }
    allSanityPost(sort: { fields: publishedAt, order: DESC }) {
      group(field: categories___title) {
        fieldValue
          nodes {
            id
            title
            excerpt
            _createdAt
            publishedAt(formatString: "MMMM DD, YYYY")
            minutes
            categories {
              featuredCategories
              title
            }
            slug {
              current
            }
            mainImage {
              asset {
                gatsbyImageData
              }
            }
            authors {
              person {
                name
                slug {
                  current
                }
              }
            }
          }
        
      }
      edges {
        node {
          id
          slug {
            current
          }
          minutes
          authors {
            person {
              name
              image {
                asset {
                  gatsbyImageData
                }
              }
              slug {
                current
              }
            }
          }
          slug {
            current
          }
          categories {
            featuredCategories
            title
          }
          excerpt
          body
          title
          publishedAt(formatString: "MMMM DD, YYYY")
          mainImage {
            asset {
              gatsbyImageData
              title
            }
          }
        }
      }
    }
    allSanityFeaturedpost {
      edges {
        node {
          title
          post {
            title
            minutes
            slug {
              current
            }
            publishedAt(formatString: "MMMM DD, YYYY")
            authors {
              person {
                name
                slug {
                  current
                }
              }
            }
            mainImage {
              asset {
                gatsbyImageData
              }
            }
            categories {
              id
              title
            }
          }
        }
      }
    }
    allSanityCategory(sort: { fields: title, order: ASC }) {
      nodes {
        featuredCategories
        title
      }
    }
  }
`
